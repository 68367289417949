import { RatingTrendItem } from '@shared/rating_scales';
import { useResponsive } from '@web/app/responsive';
import ReactECharts from 'echarts-for-react';
import React from 'react';

export const RatingTrendChart: React.FC<{
  trend: RatingTrendItem[];
  currentKey?: string;
}> = ({ trend, currentKey }) => {
  const { isMobile } = useResponsive();
  const xAxisData: string[] = [];
  const seriesData: Array<number | null> = [];

  let currentIndex = -1;
  trend.forEach((trendItem, index) => {
    if (trendItem.key === currentKey) {
      currentIndex = index;
    }
    xAxisData.push(trendItem.date);
    seriesData.push(trendItem.rating);
  });

  const chartOptions = createLineChartOptions(
    xAxisData,
    seriesData,
    currentIndex,
  );
  return (
    <ReactECharts
      style={{ width: isMobile ? 350 : 600, height: 190, padding: 0 }}
      option={chartOptions}
    />
  );
};
const createLineChartOptions = (
  xAxisData: string[],
  seriesData: Array<number | null>,
  currentIndex: number,
) => {
  return {
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 5,
    },
    series: [
      {
        data: seriesData,
        type: 'line',
        lineStyle: { color: '#999' },
        itemStyle: {
          color: (data) =>
            data.dataIndex === currentIndex ? '#f45000' : '#333',
        },
        symbol: 'circle',
        symbolSize: (_value, data) => {
          return data.dataIndex === currentIndex ? 16 : 6;
        },
        label: {
          show: true,
          position: 'top',
          formatter: (params) => (params.dataIndex === currentIndex ? '' : ''),
          color: '#f45000',
          fontSize: 16,
        },
      },
    ],
    grid: {
      left: '16px',
      right: '0%',
      top: '10%',
      bottom: '24px',
    },
  };
};
