import { GithubPullToken } from '@shared/github';
import { ISuggestedEntry } from '@shared/impacts';
import { post } from '@web/common/api';

export function createPullRequestSuggestedEntry(
  githubPullTokens: GithubPullToken[],
) {
  return post<{ githubPullTokens: `gp_${string}`[] }, ISuggestedEntry>(
    `/github-suggestions/suggested_entry`,
    { githubPullTokens: githubPullTokens },
  );
}
