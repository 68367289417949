import { zonedTimeToUtc } from 'date-fns-tz';

export const DefaultTimezone = 'America/New_York';
export enum SupportedTimezone {
  'Pacific/Honolulu' = 'Pacific/Honolulu',
  'America/Anchorage' = 'America/Anchorage',
  'America/Whitehorse' = 'America/Whitehorse',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'America/Yellowknife' = 'America/Yellowknife',
  'America/Vancouver' = 'America/Vancouver',
  'America/Denver' = 'America/Denver',
  'America/Regina' = 'America/Regina',
  'America/Phoenix' = 'America/Phoenix',
  'America/Chicago' = 'America/Chicago',
  'America/Winnipeg' = 'America/Winnipeg',
  'America/Detroit' = 'America/Detroit',
  'America/New_York' = 'America/New_York',
  'America/Montreal' = 'America/Montreal',
  'America/Toronto' = 'America/Toronto',
  'America/Goose_Bay' = 'America/Goose_Bay',
  'America/Halifax' = 'America/Halifax',
  'America/Moncton' = 'America/Moncton',
  'America/St_Johns' = 'America/St_Johns',
}

export const getCurrentTimezoneHour = (tzCode: string) => {
  const formatter = new Intl.DateTimeFormat([], {
    timeZone: tzCode,
    hour: 'numeric',
    hour12: false,
  });
  const hour = parseInt(formatter.format(new Date()));
  return hour === 24 ? 0 : hour;
};

export const getTimezoneDate = (tzCode: string, date: Date) => {
  const formatter = new Intl.DateTimeFormat('fr-CA', {
    timeZone: tzCode,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return formatter.format(date);
};

export const getCurrentTimezoneDate = (tzCode: string) => {
  return getTimezoneDate(tzCode, new Date());
};

export const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export function date(dateString: string, hour: number, tzCode: string) {
  return zonedTimeToUtc(
    `${dateString}T${hour.toString().padStart(2, '0')}:00:00`,
    tzCode,
  );
}
