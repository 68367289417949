import { IBedrockReport } from '@backend/bedrock/IBedrockReport';
import { isToken } from '@shared/isToken';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { KnownBlock } from '@slack/types';
import { ChatCompletionCreateParamsBase } from 'openai/resources/chat/completions';

import { IImpact } from './impacts';

export type BedrockConfigurationToken = `bc_${string}`;
export const isBedrockConfigurationToken = isToken('bc_');

export interface IBedrockConfiguration {
  token: BedrockConfigurationToken;
  organizationToken: OrganizationToken;
  enrollmentLimit: number;
}

export type BedrockEnrollmentToken = `be_${string}`;

export interface IBedrockEnrollment {
  token: BedrockEnrollmentToken;
  user: IUser;
  enrolledAt: Date;
  linearName?: string;
  jiraName?: string;
}

export interface GetBedrockConfigurationResponse {
  configuration: IBedrockConfiguration;
  enrolledChannelCount: number;
  /* @deprecated use `enrolledChannelCount` or fetch channels separately. Channel names are no longer included. */
  channels: IChannelInfo[];
}

export interface IChannelInfo {
  id: string;
  state: 'invalid' | 'active';
  name: string;
}

export type BedrockMechanism = 'journal' | 'dm';
export interface RunBedrockRequest {
  mechanism: BedrockMechanism;
  maxEntryCount: number;
  suggestedEntryCount: number;
  startDate: Date;
  endDate: Date;
  targetUserTokens?: UserToken[];
  adjacentMessageCount: number;
  type: BedrockReportType;
}

export type BedrockBatchToken = `bbt_${string}`;
export interface IBedrockBatch {
  token: BedrockBatchToken;
  type: BedrockReportType;
  startDate?: Date;
  endDate?: Date;
}

export type BedrockRunToken = `br_${string}`;
export interface IBedrockRun {
  token: BedrockRunToken;
  user: IUser;
  journal?: BedrockJournalSpec[];
  journalSentAt?: Date;
  blocks?: KnownBlock[];
  blocksSentAt?: Date;
  managerSummaryBlocks?: KnownBlock[];
  managerSummarySentAt?: Date;
  raw?: string;
  report?: IBedrockReport; // TODO: move type to shared.
  prompt?: ChatCompletionCreateParamsBase['messages'];
  messagesSeen?: number;
  userMessagesSeen?: number;
  pullRequestsSeen?: number;
  completeTicketsSeen?: number;
}

export interface IBedrockBatchRow extends IBedrockBatch {
  totalRuns: number;
  totalSent: number;
}
export interface GetBedrockBatchesResponse {
  batches: IBedrockBatchRow[];
}

export interface GetBedrockRunsResponse {
  batch: IBedrockBatch;
  runs: IBedrockRun[];
}

export type BedrockJournalSpec = Pick<
  IImpact,
  'descriptionMarkdown' | 'title' | 'date'
>;

export type BedrockReportPresentation = Pick<
  IBedrockRun,
  'raw' | 'journal' | 'blocks' | 'prompt' | 'managerSummaryBlocks' | 'report'
>;

export type BedrockReportUpdate = BedrockReportPresentation &
  BedrockReportStatistics;

export type BedrockReportStatistics = Pick<
  IBedrockRun,
  | 'messagesSeen'
  | 'userMessagesSeen'
  | 'pullRequestsSeen'
  | 'completeTicketsSeen'
>;

export interface CreateBedrockBatchRequest {
  type: BedrockReportType;
  startDate: Date;
  endDate: Date;
  targetUserTokens?: UserToken[];
}

export interface BedrockRunOpts {
  maxEntryCount: number;
  suggestedEntryCount: number;
  adjacentMessageCount: number;
  /** Whether the prompt used to produce the report should be saved to the `BedrockRun`. `runTokens` must be included when using `savePrompt`. */
  savePrompt?: boolean;
  /** The minimum number of messages required for a report to be generated for a given user (or run). */
  minimumRequiredMessagesPerReport?: number;
}

export type ChannelList = IBedrockChannel[];

export enum BedrockReportSendMode {
  ONCE = 'ONCE',
  FORCE = 'FORCE',
}
export enum BedrockReportType {
  BASIC = 'BASIC',
  WITH_MESSAGE = 'WITH_MESSAGE',
  SYNTHETIC_PULL_REQUESTS = 'SYNTHETIC_PULL_REQUESTS',
  GROUPED_PULL_REQUESTS = 'GROUPED_PULL_REQUESTS',
  EXPLICIT_USER_ID = 'EXPLICIT_USER_ID',
  SYNTHETIC_PR_AND_TICKET = 'SYNTHETIC_PR_AND_TICKET',
  SYNTHETIC_PR_AND_TICKET_V2 = 'SYNTHETIC_PR_AND_TICKET_V2',
}

export enum BedrockSummarySendMode {
  ONCE = 'ONCE',
  FORCE = 'FORCE',
}

export type BedrockEnrollmentUpdate = Pick<
  IBedrockEnrollment,
  'linearName' | 'jiraName'
>;

export type BedrockChannelInfoToken = `bci_${string}`;
export interface IBedrockChannelInfo {
  token: BedrockChannelInfoToken;
  lastUpdateScheduled?: Date;
  lastUpdated?: Date;
  channels?: IBedrockChannel[];
}

export interface IBedrockChannel {
  id: string;
  name: string;
}

export const MIN_TIME_BETWEEN_CHANNEL_REFRESH_MS = 120 * 1000;

export enum BedrockConfigDebugReportVersion {
  VERSION_0 = 'VERSION_0',
}

export interface BedrockConfigDebugReport {
  version: BedrockConfigDebugReportVersion.VERSION_0;
  users: BedrockConfigDebugUserValues[];
  matches: Record<UserToken, IConfigMatches>;
  unknownJiraNames: string[];
  unknownLinearNames: string[];
  unknownGithubUsers: string[];
}

export interface BedrockConfigDebugUserValues
  extends Pick<IUser, 'token' | 'name'> {
  linearName?: string;
  jiraName?: string;
  githubUser?: string;
}

export interface IConfigMatches {
  linear: boolean;
  jira: boolean;
  github: boolean;
}

export type BedrockConfigDebugRunToken = `bcd_${string}`;
export interface IBedrockConfigDebugRun {
  token: BedrockConfigDebugRunToken;
  startDate: Date;
  endDate: Date;
  report: BedrockConfigDebugReport;
  createdDate: Date;
}
