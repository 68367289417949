import { UserMapItem } from '@shared/types';
import { Header3 } from '@web/components/typography';
import humanparser from 'humanparser';
import Mustache from 'mustache';
import React from 'react';

export const QuestionText: React.FC<{
  text: string;
  receiver?: UserMapItem;
  includeOptionalAnnotation?: boolean;
}> = ({ text, receiver, includeOptionalAnnotation }) => {
  const parsedName = receiver?.name ? humanparser.parseName(receiver.name) : {};
  const renderText = text + (includeOptionalAnnotation ? ' (optional)' : '');
  return (
    <Header3 style={{ whiteSpace: 'pre-wrap' }}>
      {Mustache.render(renderText, parsedName)}
    </Header3>
  );
};
