import { useGetUserDashboard } from '@client/ReviewCyclesClient';
import { useUserDetails } from '@client/UsersClient';
import { UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { ReviewCyclesUserDashboard } from './ReviewCyclesUserDashboard';

export const ReviewCyclesManagerPage: React.FC = () => {
  const { userToken } = useParams<{ userToken: UserToken }>();
  const { data: user } = useUserDetails(userToken);

  const { data: userDashboardSummary } = useGetUserDashboard(userToken);

  if (!userDashboardSummary || !user) {
    return (
      <PageContent>
        <PageHeader
          title="Cycles"
          mobileTitle="Cycles"
          navigateBack
          defaultNavigateBackTo="/team"
        />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <PageHeader
        title={`Cycles for ${user.name}`}
        mobileTitle={`Cycles for ${user.name}`}
        navigateBack
        defaultNavigateBackTo="/team"
      />
      <ReviewCyclesUserDashboard
        dashboardRows={userDashboardSummary.dashboardRows}
        isReport={userDashboardSummary.isReport}
      />
    </PageContent>
  );
};
