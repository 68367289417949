import { addQuestion, updateQuestion } from '@client/QuestionSetsClient';
import { QuestionType, QuestionTypeLabels } from '@shared/QuestionType';
import { IQuestionSet } from '@shared/questionSets';
import {
  IQuestion,
  IRatingQuestion,
  isRatingQuestion,
} from '@shared/questions';
import { RatingScale } from '@shared/rating_scales';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Checkbox, Input, Modal, Select, Typography, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { sortBy, times } from 'lodash';
import React, { useState } from 'react';

interface Props {
  onCancel: () => void;
  onUpdate: () => void;
  open: boolean;
  questionSet: IQuestionSet;
  question?: IQuestion;
  readonly?: boolean;
  questionTypes?: QuestionType[];
}

const OptionLabels = {
  [QuestionType.OPEN_ENDED]: `${
    QuestionTypeLabels[QuestionType.OPEN_ENDED]
  } question`,
  [QuestionType.RATING]: `${QuestionTypeLabels[QuestionType.RATING]} question`,
  [QuestionType.LABEL]: QuestionTypeLabels[QuestionType.LABEL],
};

export const EditQuestionModal: React.FC<Props> = ({
  onUpdate,
  onCancel,
  open,
  questionSet,
  question,
  readonly,
  questionTypes = [
    QuestionType.OPEN_ENDED,
    QuestionType.RATING,
    QuestionType.LABEL,
  ],
}) => {
  const [scaleLabels, setScaleLabels] = useState(
    question?.type === QuestionType.RATING ? flattenScale(question) : {},
  );
  const [text, setText] = useState(question?.text ?? '');
  const [type, setType] = useState(question?.type ?? questionTypes[0]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [required, setRequired] = React.useState(
    question ? question.required : false,
  );

  const handleOk = async () => {
    setIsSaving(true);

    try {
      const questionAttributes: Partial<IQuestion> = {
        text: text?.trim(),
        type,
        scale: createScale(type, scaleLabels),
        required,
      };
      const error = validateQuestion(questionAttributes);
      if (error) {
        void message.error(error);
        return;
      }

      if (question) {
        await updateQuestion(
          questionSet.token,
          question.token,
          questionAttributes,
        );
      } else {
        await addQuestion(questionSet.token, questionAttributes);
      }

      void message.success('Success');
      onUpdate();
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
  };

  return (
    <Modal
      title={
        readonly ? 'View Question' : question ? 'Edit Question' : 'Add Question'
      }
      open={open}
      onOk={() => {
        void handleOk();
      }}
      okButtonProps={{
        disabled: readonly,
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="600px"
    >
      <Column>
        {questionTypes.length === 1 ? null : !question ? (
          <Select
            disabled={isSaving}
            value={type}
            onChange={(newType) => {
              setType(newType);
            }}
            style={{ width: 200 }}
          >
            {questionTypes.map((section) => (
              <Select.Option key={section} value={section}>
                {OptionLabels[section]}
              </Select.Option>
            ))}
          </Select>
        ) : type ? (
          <Text style={{ fontWeight: 500 }}>{OptionLabels[type]}</Text>
        ) : null}
        <Spacer size={18} />
        <Column>
          <Text>{type === QuestionType.LABEL ? `Text` : `Question Text`}</Text>
          <TextArea
            autoSize
            disabled={isSaving || readonly}
            autoFocus
            value={text}
            onChange={(e) => {
              setText(e.currentTarget.value);
            }}
          />
        </Column>
        <Spacer size={18} />
        {type === QuestionType.RATING && (
          <>
            <Typography.Title level={5}>Responses</Typography.Title>
            <Column gap={12}>
              {times(5).map((index) => (
                <Input
                  key={`scale_label_${index + 1}`}
                  prefix={`${index + 1}.`}
                  disabled={isSaving || readonly}
                  value={scaleLabels[`scaleLabel${index + 1}`]}
                  onChange={(e) => {
                    const label = e.currentTarget.value;
                    setScaleLabels((labels) => {
                      return {
                        ...labels,
                        [`scaleLabel${index + 1}`]: label,
                      };
                    });
                  }}
                />
              ))}
            </Column>
          </>
        )}
        <Spacer size={12} />
        {![QuestionType.LABEL].includes(type) ? (
          <Row gap={6}>
            <Checkbox
              checked={required}
              onChange={() => {
                setRequired(!required);
              }}
              disabled={readonly}
            />
            <Text>Required</Text>
          </Row>
        ) : undefined}
      </Column>
    </Modal>
  );
};

interface IScaleLabels {
  scaleLabel1?: string;
  scaleLabel2?: string;
  scaleLabel3?: string;
  scaleLabel4?: string;
  scaleLabel5?: string;
}

const flattenScale = (question: IRatingQuestion): IScaleLabels => {
  const sortedScale = sortBy(question?.scale ?? [], 'value');
  if (isRatingQuestion(question)) {
    return {
      scaleLabel1: sortedScale[0]?.label,
      scaleLabel2: sortedScale[1]?.label,
      scaleLabel3: sortedScale[2]?.label,
      scaleLabel4: sortedScale[3]?.label,
      scaleLabel5: sortedScale[4]?.label,
    };
  }
  return {};
};

const createScale = (
  type: QuestionType,
  scaleLabels: IScaleLabels,
): RatingScale | undefined => {
  if (type !== QuestionType.RATING) {
    return undefined;
  }

  const scale: RatingScale = [];
  if (scaleLabels.scaleLabel1?.trim()) {
    scale.push({
      value: scale.length + 1,
      label: scaleLabels.scaleLabel1,
    });
  }
  if (scaleLabels.scaleLabel2?.trim()) {
    scale.push({
      value: scale.length + 1,
      label: scaleLabels.scaleLabel2,
    });
  }
  if (scaleLabels.scaleLabel3?.trim()) {
    scale.push({
      value: scale.length + 1,
      label: scaleLabels.scaleLabel3,
    });
  }
  if (scaleLabels.scaleLabel4?.trim()) {
    scale.push({
      value: scale.length + 1,
      label: scaleLabels.scaleLabel4,
    });
  }
  if (scaleLabels.scaleLabel5?.trim()) {
    scale.push({
      value: scale.length + 1,
      label: scaleLabels.scaleLabel5,
    });
  }
  return scale;
};

const validateQuestion = (question: Partial<IQuestion>) => {
  if (!question.text) {
    return 'Question text is required';
  }

  if (question.type === QuestionType.RATING) {
    if (question.scale.length < 2) {
      return 'At least 2 responses are required';
    }
  }
};
