import Bugsnag, { OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ISession, UserRole } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import * as React from 'react';

import { REACT_APP_BUILD_HASH, REACT_APP_BUILD_TIME } from './build';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production', 'staging'],
});
const BugsnagErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

export const ErrorBoundary: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, impersonator } = useAuth();

  const handleErrorEvent: OnErrorCallback = (event) => {
    event.addMetadata('user', {
      userToken: user?.token,
      impersonatorToken: impersonator?.token,
    });
  };
  return (
    <BugsnagErrorBoundary onError={handleErrorEvent}>
      {children}
    </BugsnagErrorBoundary>
  );
};

let errorSession: ISession;
export const setErrorSession = (session: ISession) => {
  errorSession = session;
};

const userSessionDetails = () => {
  return {
    organization: errorSession.user?.organizationToken,
    user: errorSession.user?.token,
    role: errorSession.user?.role,
    impersonator: errorSession.impersonator?.token,
    build: {
      ms: REACT_APP_BUILD_TIME,
      hash: REACT_APP_BUILD_HASH,
    },
  };
};

export const reportApiError = (
  requestMethod: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  apiUrl: string,
  statusCode: number,
  responseText?: string,
) => {
  // ignore signed out or impersonating users GET requests
  if (!shouldReportApiError(requestMethod, statusCode)) {
    console.error('Skipping api error report', {
      apiUrl,
      statusCode,
      requestMethod,
      responseText,
      session: userSessionDetails(),
    });
    return;
  }

  Bugsnag.notify(
    new Error(
      `${requestMethod} ${apiUrl} responded with a ${statusCode}: (${
        responseText ?? 'n/a'
      })`,
    ),
    (event) => {
      if (errorSession) {
        event.addMetadata('user', userSessionDetails());
      }
    },
    (err) => {
      if (err) {
        console.error('Failed to send bugsnag: ', err);
      }
    },
  );
};

const shouldReportApiError = (
  requestMethod: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  statusCode,
) => {
  if (requestMethod === 'GET') {
    // do not report errors on logged out users
    if (!errorSession) {
      return false;
    }
    // do not report errors for internal users impersonating others
    if (errorSession.impersonator) {
      return false;
    }
    // do not report errors for internal users on internal pages
    if (
      [UserRole.SUPER, UserRole.OPERATIONS].includes(errorSession.user.role)
    ) {
      return false;
    }
  }
  // do not report conflict errors as these are how the backend indicates
  // validation issues to the frontend.
  if (statusCode === 409) {
    return false;
  }

  return true;
};
