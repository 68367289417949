import { dateString } from '@shared/dateString';
import { ProductName, productDetails } from '@shared/reflections';
import { IReviewCycle, ShareMode } from '@shared/review-cycles';
import { patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { SelectDate, SelectDateRange } from '@web/components/SelectDate';
import { SelectHour } from '@web/components/SelectDateHour';
import { Column, Row, WrapRow } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Button, Divider, message } from 'antd';
import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';

interface IReviewCycleConfig {
  name: string;
  peerReviewCycleEnabled: boolean;
  peerSelectionStartDate?: string;
  peerSelectionEndDate?: string;
  peerApprovalStartDate?: string;
  peerApprovalEndDate?: string;
  peerFeedbackStartDate?: string;
  peerFeedbackEndDate?: string;
  peerReviewShareMode: ShareMode;
  upwardReviewCycleEnabled: boolean;
  upwardFeedbackStartDate?: string;
  upwardFeedbackEndDate?: string;
  selfReflectionCycleEnabled: boolean;
  selfReflectionProductName: ProductName;
  selfReviewStartDate?: string;
  selfReviewEndDate?: string;
  managerReviewProductName: ProductName;
  managerReflectionCycleEnabled: boolean;
  managerReviewStartDate?: string;
  managerReviewEndDate?: string;
  sharedByDate?: string;
  notificationHour?: number;
}

export const EditReviewCycleScheduleForm: React.FC<{
  onSave: (savedReviewCycle: IReviewCycle) => void;
  onCancel?: () => void;
  reviewCycleToken: string;
  modalButtonMode?: boolean;
}> = ({ onSave, onCancel, reviewCycleToken, modalButtonMode }) => {
  const isEdit = !!reviewCycleToken;
  const { data: reviewCycle } = useApi<IReviewCycle>(
    isEdit ? `/review-cycles/${reviewCycleToken}` : null,
  );
  const [reviewCycleConfig, setReviewCycleConfig] =
    React.useState<IReviewCycleConfig>({} as any);
  React.useEffect(() => {
    if (reviewCycle) {
      const updatedConfig: IReviewCycleConfig = {
        name: reviewCycle.name,
        peerReviewCycleEnabled: reviewCycle.peerReviewCycleEnabled,
        peerSelectionStartDate: reviewCycle.peerSelectionStartDate
          ? dateString(reviewCycle.peerSelectionStartDate)
          : null,
        peerSelectionEndDate: reviewCycle.peerSelectionEndDate
          ? dateString(reviewCycle.peerSelectionEndDate)
          : null,
        peerApprovalStartDate: reviewCycle.peerApprovalStartDate
          ? dateString(reviewCycle.peerApprovalStartDate)
          : null,
        peerApprovalEndDate: reviewCycle.peerApprovalEndDate
          ? dateString(reviewCycle.peerApprovalEndDate)
          : null,
        peerFeedbackStartDate: reviewCycle.peerFeedbackStartDate
          ? dateString(reviewCycle.peerFeedbackStartDate)
          : null,
        peerFeedbackEndDate: reviewCycle.peerFeedbackEndDate
          ? dateString(reviewCycle.peerFeedbackEndDate)
          : null,
        peerReviewShareMode: reviewCycle.peerReviewShareMode,
        upwardReviewCycleEnabled: reviewCycle.upwardReviewCycleEnabled,
        upwardFeedbackStartDate: reviewCycle.upwardFeedbackStartDate
          ? dateString(reviewCycle.upwardFeedbackStartDate)
          : null,
        upwardFeedbackEndDate: reviewCycle.upwardFeedbackEndDate
          ? dateString(reviewCycle.upwardFeedbackEndDate)
          : null,
        selfReflectionCycleEnabled: reviewCycle.selfReflectionCycleEnabled,
        selfReviewStartDate: reviewCycle.selfReviewStartDate
          ? dateString(reviewCycle.selfReviewStartDate)
          : null,
        selfReviewEndDate: reviewCycle.selfReviewEndDate
          ? dateString(reviewCycle.selfReviewEndDate)
          : null,
        selfReflectionProductName: reviewCycle.selfReflectionProductName,
        managerReviewProductName: reviewCycle.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycle.managerReflectionCycleEnabled,
        managerReviewStartDate: reviewCycle.managerReviewStartDate
          ? dateString(reviewCycle.managerReviewStartDate)
          : null,
        managerReviewEndDate: reviewCycle.managerReviewEndDate
          ? dateString(reviewCycle.managerReviewEndDate)
          : null,
        sharedByDate: reviewCycle.sharedByDate
          ? dateString(reviewCycle.sharedByDate)
          : null,
        notificationHour: reviewCycle.notificationHour,
      };
      setReviewCycleConfig(updatedConfig);
    }
  }, [reviewCycle]);

  const handleSave = async () => {
    try {
      const body: IReviewCycleConfig = {
        name: reviewCycleConfig.name,
        peerReviewCycleEnabled: reviewCycleConfig.peerReviewCycleEnabled,
        peerSelectionStartDate: reviewCycleConfig.peerSelectionStartDate
          ? `${reviewCycleConfig.peerSelectionStartDate}T23:59:59.999Z`
          : null,
        peerSelectionEndDate: reviewCycleConfig.peerSelectionEndDate
          ? `${reviewCycleConfig.peerSelectionEndDate}T23:59:59.999Z`
          : null,
        peerApprovalStartDate: reviewCycleConfig.peerApprovalStartDate
          ? `${reviewCycleConfig.peerApprovalStartDate}T23:59:59.999Z`
          : null,
        peerApprovalEndDate: reviewCycleConfig.peerApprovalEndDate
          ? `${reviewCycleConfig.peerApprovalEndDate}T23:59:59.999Z`
          : null,
        peerFeedbackStartDate: reviewCycleConfig.peerFeedbackStartDate
          ? `${reviewCycleConfig.peerFeedbackStartDate}T23:59:59.999Z`
          : null,
        peerFeedbackEndDate: reviewCycleConfig.peerFeedbackEndDate
          ? `${reviewCycleConfig.peerFeedbackEndDate}T23:59:59.999Z`
          : null,
        peerReviewShareMode: reviewCycleConfig.peerReviewShareMode,
        upwardReviewCycleEnabled: reviewCycleConfig.upwardReviewCycleEnabled,
        upwardFeedbackStartDate: reviewCycleConfig.upwardFeedbackStartDate
          ? `${reviewCycleConfig.upwardFeedbackStartDate}T23:59:59.999Z`
          : null,
        upwardFeedbackEndDate: reviewCycleConfig.upwardFeedbackEndDate
          ? `${reviewCycleConfig.upwardFeedbackEndDate}T23:59:59.999Z`
          : null,
        selfReflectionCycleEnabled:
          reviewCycleConfig.selfReflectionCycleEnabled,
        selfReviewStartDate: reviewCycleConfig.selfReviewStartDate
          ? `${reviewCycleConfig.selfReviewStartDate}T23:59:59.999Z`
          : null,
        selfReviewEndDate: reviewCycleConfig.selfReviewEndDate
          ? `${reviewCycleConfig.selfReviewEndDate}T23:59:59.999Z`
          : null,
        selfReflectionProductName: reviewCycleConfig.selfReflectionProductName,
        managerReviewProductName: reviewCycleConfig.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycleConfig.managerReflectionCycleEnabled,
        managerReviewStartDate: reviewCycleConfig.managerReviewStartDate
          ? `${reviewCycleConfig.managerReviewStartDate}T23:59:59.999Z`
          : null,
        managerReviewEndDate: reviewCycleConfig.managerReviewEndDate
          ? `${reviewCycleConfig.managerReviewEndDate}T23:59:59.999Z`
          : null,
        sharedByDate: reviewCycleConfig.sharedByDate
          ? `${reviewCycleConfig.sharedByDate}T23:59:59.999Z`
          : null,
        notificationHour: reviewCycleConfig.notificationHour, // Explicitly pass `null` to clear out the `notificationHour`.
      };
      let savedReviewCycle: IReviewCycle;
      if (isEdit) {
        savedReviewCycle = await patch<IReviewCycleConfig, IReviewCycle>(
          `/review-cycles/${reviewCycleToken}`,
          body,
        );
      } else {
        savedReviewCycle = await post<IReviewCycleConfig, IReviewCycle>(
          '/review-cycles',
          body,
        );
      }

      void message.success('Success');
      onSave(savedReviewCycle);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDateUpdated =
    (property: keyof IReviewCycleConfig) => (date?: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [property]: date,
      });
    };
  const handleDateRangeUpdated =
    (
      startProperty: keyof IReviewCycleConfig,
      endProperty: keyof IReviewCycleConfig,
    ) =>
    (startDate: string, endDate: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [startProperty]: startDate,
        [endProperty]: endDate,
      });
    };

  const handlePeerSelectionDateRangeUpdated = () => {
    handleDateRangeUpdated('peerSelectionStartDate', 'peerSelectionEndDate');
    handleDateRangeUpdated('peerApprovalStartDate', 'peerApprovalEndDate');
  };

  const handleNotificationHourChanged = (notificationHour: number) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      notificationHour,
    });
  };

  const canSave =
    !!reviewCycleConfig.name && !!reviewCycleConfig.notificationHour;

  return (
    <Column gap={24}>
      <Column gap={12} style={{ alignItems: 'flex-start', width: 794 }}>
        {reviewCycleConfig.peerReviewCycleEnabled && (
          <>
            <Column gap={12}>
              <WrapRow gap={12}>
                <Column style={{ width: 250 }}>
                  <Text>Peer Selection</Text>
                  <SelectDateRange
                    start={reviewCycleConfig.peerSelectionStartDate}
                    end={reviewCycleConfig.peerSelectionEndDate}
                    onChange={handlePeerSelectionDateRangeUpdated}
                    allowClear={true}
                  />
                </Column>
                <Column style={{ width: 250 }}>
                  <Text>Peer Feedback</Text>
                  <SelectDateRange
                    start={reviewCycleConfig.peerFeedbackStartDate}
                    end={reviewCycleConfig.peerFeedbackEndDate}
                    onChange={handleDateRangeUpdated(
                      'peerFeedbackStartDate',
                      'peerFeedbackEndDate',
                    )}
                    allowClear={true}
                  />
                </Column>
              </WrapRow>
            </Column>
            <Divider style={{ margin: '6px 0' }} />
          </>
        )}
        {reviewCycleConfig.upwardReviewCycleEnabled && (
          <>
            <Column gap={12}>
              <WrapRow gap={12}>
                <Column style={{ width: 250 }}>
                  <Text>Upward Feedback</Text>
                  <SelectDateRange
                    start={reviewCycleConfig.upwardFeedbackStartDate}
                    end={reviewCycleConfig.upwardFeedbackEndDate}
                    onChange={handleDateRangeUpdated(
                      'upwardFeedbackStartDate',
                      'upwardFeedbackEndDate',
                    )}
                    allowClear={true}
                  />
                </Column>
              </WrapRow>
            </Column>
            <Divider style={{ margin: '6px 0' }} />
          </>
        )}
        {reviewCycleConfig.selfReflectionCycleEnabled && (
          <>
            <Column gap={12}>
              <WrapRow gap={12}>
                <Column style={{ width: 250 }}>
                  <Text>
                    Self{' '}
                    {pluralize(
                      productDetails(
                        reviewCycleConfig.selfReflectionProductName,
                      ).titleCase,
                    )}
                  </Text>
                  <SelectDateRange
                    start={reviewCycleConfig.selfReviewStartDate}
                    end={reviewCycleConfig.selfReviewEndDate}
                    onChange={handleDateRangeUpdated(
                      'selfReviewStartDate',
                      'selfReviewEndDate',
                    )}
                    allowClear={true}
                  />
                </Column>
              </WrapRow>
            </Column>
            <Divider style={{ margin: '6px 0' }} />
          </>
        )}
        {reviewCycleConfig.managerReflectionCycleEnabled && (
          <>
            <Column gap={12}>
              <WrapRow gap={12}>
                <Column style={{ width: 250 }}>
                  <Text>
                    Manager{' '}
                    {pluralize(
                      productDetails(reviewCycleConfig.managerReviewProductName)
                        .titleCase,
                    )}
                  </Text>
                  <SelectDateRange
                    start={reviewCycleConfig.managerReviewStartDate}
                    end={reviewCycleConfig.managerReviewEndDate}
                    onChange={handleDateRangeUpdated(
                      'managerReviewStartDate',
                      'managerReviewEndDate',
                    )}
                    allowClear={true}
                  />
                </Column>
                <Column style={{ width: 250 }}>
                  <Text>Shared By (optional)</Text>
                  <SelectDate
                    value={reviewCycleConfig.sharedByDate}
                    onChange={handleDateUpdated('sharedByDate')}
                  />
                </Column>
              </WrapRow>
            </Column>
            <Divider style={{ margin: '6px 0' }} />
          </>
        )}
        <Column>
          <Text>Notification Time</Text>
          <SelectHour
            hour={reviewCycleConfig.notificationHour}
            onHourChanged={handleNotificationHourChanged}
          />
        </Column>
      </Column>
      <PaneButtons
        style={{
          marginTop: 12,
          flexDirection: modalButtonMode ? 'row-reverse' : 'row',
        }}
      >
        <Button type="primary" disabled={!canSave} onClick={handleSave}>
          Save
        </Button>
        {onCancel && <Button onClick={handleCancel}>Cancel</Button>}
      </PaneButtons>
    </Column>
  );
};

export const PaneButtons = styled(Row)`
  gap: 6px;

  .ant-btn {
    width: 120px;
  }
`;
