import { createPullRequestSuggestedEntry } from '@client/GithubSuggestionsClient';
import { getSuggestedEntry } from '@client/ImpactsClient';
import { Feature } from '@shared/features';
import { GithubPullToken, IGithubPull } from '@shared/github';
import {
  ISuggestedEntry,
  SuggestedEntryState,
  SuggestedEntryToken,
} from '@shared/impacts';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { useFeature } from '@web/common/useFeature';
import { Pane } from '@web/components/Pane';
import { Column, ResponsiveRow, Row, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Button, message } from 'antd';
import React, { useState } from 'react';
import { Bars } from 'react-loader-spinner';
import styled from 'styled-components';
import { useInterval } from 'usehooks-ts';

import { LoadingModal } from './LoadingModal';
import { SelectPullRequests } from './SelectPullRequests';
import { WorkItems } from './WorkItems';

export const CreateFromPullRequestBanner: React.FC<{
  onSuggest: (suggestedEntry: ISuggestedEntry) => void;
}> = ({ onSuggest }) => {
  const { isMobile } = useResponsive();
  const { organization, user } = useAuth();
  const { booleanValue: githubIntegrationEnabled } = useFeature(
    Feature.GITHUB_INTEGRATION,
  );
  const [suggestedEntryToken, setSuggestedEntryToken] =
    useState<SuggestedEntryToken | null>(null);
  const [pulls, setPulls] = React.useState<IGithubPull[]>([]);

  const loadSuggestedEntry = async () => {
    if (!suggestedEntryToken) {
      return;
    }

    const suggestedEntry = await getSuggestedEntry(suggestedEntryToken);
    if (suggestedEntry.state === SuggestedEntryState.READY) {
      setSuggestedEntryToken(null);
      onSuggest(suggestedEntry);
    } else if (suggestedEntry.state === SuggestedEntryState.FAILED) {
      void message.error('Failed to generate a suggestion');
      setSuggestedEntryToken(null);
    }
  };

  useInterval(loadSuggestedEntry, 1000);

  if (
    !githubIntegrationEnabled ||
    !organization.githubFeatureActive ||
    !user.githubLogin
  ) {
    return null;
  }

  const handleChange = (newPulls: IGithubPull[]) => {
    setPulls(newPulls);
  };

  const handlePullRemoved = (token: GithubPullToken) => {
    const newPulls = pulls.filter((pull) => pull.token !== token);
    setPulls(newPulls);
  };

  const handleGenerateEntry = async () => {
    if (suggestedEntryToken) {
      return;
    }

    const githubPullTokens = pulls.map((pull) => pull.token);

    const suggestedEntry = await createPullRequestSuggestedEntry(
      githubPullTokens,
    );
    setSuggestedEntryToken(suggestedEntry.token);
  };

  const handleCancel = () => {
    setSuggestedEntryToken(null);
  };

  if (pulls.length === 0) {
    return (
      <Container>
        <Pane style={isMobile ? {} : { padding: '12px 24px' }}>
          <ResponsiveRow
            gap={isMobile ? 0 : 12}
            style={isMobile ? { alignItems: 'flex-start' } : {}}
          >
            <Text>Generate an entry from your work</Text>
            <SelectPullRequests initialPulls={pulls} onChange={handleChange} />
          </ResponsiveRow>
        </Pane>
      </Container>
    );
  }

  const isGenerating = !!suggestedEntryToken;

  return (
    <Container>
      <Pane>
        <Column gap={12} style={{ alignItems: 'flex-start' }}>
          <Header3>Recent Work</Header3>
          <WorkItems pulls={pulls} onRemove={handlePullRemoved} />
          <Row gap={12}>
            <Button type="primary" onClick={handleGenerateEntry}>
              Generate entry
            </Button>
            <SelectPullRequests initialPulls={pulls} onChange={handleChange} />
          </Row>
        </Column>
      </Pane>
      {isGenerating && (
        <LoadingModal>
          <div style={{ textAlign: 'center', padding: '12px 0' }}>
            <Header3>Generating a suggestion based on your selections.</Header3>
            <Spacer size={24} />
            <Bars
              height="64"
              width="64"
              color="var(--primary-color)"
              ariaLabel="bars-loading"
              wrapperStyle={{ justifyContent: 'center' }}
              wrapperClass=""
              visible={true}
            />
            <Spacer size={24} />
            <Header3>This might take a minute...</Header3>
            <Spacer size={32} />
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </LoadingModal>
      )}
    </Container>
  );
};

const Container = styled(Column)`
  gap: 6px;
  margin-bottom: 24px;
`;
