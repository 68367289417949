import { useManagerWidgetDetails } from '@client/ReviewCyclesClient';
import { IUser } from '@shared/types';
import { FullWidthDivider } from '@web/components/Pane';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { ReviewCycleDashboard } from '@web/review-cycles/ReviewCycleDashboard';
import { Button } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const ManagerReviewCycleWidget: React.FC<{ user: IUser }> = ({
  user,
}) => {
  const { data: details } = useManagerWidgetDetails(user.token);

  if (!details?.reviewCycle || !details?.dashboardRow) {
    return null;
  }

  const { dashboardRow, reviewCycle, isReport } = details;
  return (
    <Column style={{ background: 'white' }}>
      <FullWidthDivider />
      <Row>
        {isReport ? (
          <Link to={`/cycles/${reviewCycle.token}`}>
            <Header2>{reviewCycle.name ?? 'Latest Cycle'}</Header2>
          </Link>
        ) : (
          <Header2>{reviewCycle.name ?? 'Latest Cycle'}</Header2>
        )}
        <GrowingSpacer min={12} />
        <Link to={`/team/${user.token}/cycles`}>
          <Button>View all</Button>
        </Link>
      </Row>
      <Spacer size={12} />
      <ReviewCycleDashboard
        reviewCycle={reviewCycle}
        dashboardRows={[dashboardRow]}
        isWidgetView
      />
    </Column>
  );
};
