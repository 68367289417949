import { Feature } from '@shared/features';
import { ProductName, productDetails } from '@shared/reflections';
import { IReviewCycleUserDashboardRow, ShareMode } from '@shared/review-cycles';
import { useFeature } from '@web/common/useFeature';
import { Column } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';

import {
  ManagerReflectionCell,
  PeerFeedbackGivenCell,
  PeerFeedbackReceivedCell,
  PeerSelectionActionCell,
  ReviewsSharedCell,
  SelfReflectionCell,
  UpwardFeedbackReceivedCell,
} from './dashboard';

export const ReviewCyclesUserDashboard: React.FC<{
  dashboardRows: IReviewCycleUserDashboardRow[];
  isReport: boolean;
  onChange?: () => void;
  emptyText?: string;
}> = ({
  dashboardRows,
  isReport,
  onChange = () => {},
  emptyText = 'No cycles found',
}) => {
  const { booleanValue: disableCycleRelease } = useFeature(
    Feature.DISABLE_CYCLE_RELEASE,
  );

  // It's possible that there is a mix of cycles with different self reflection
  // product names. Choose the most recent for the column heading.
  const rowWithSelfReflection = dashboardRows.find(
    (row) => row.reviewCycle.selfReflectionCycleEnabled,
  );
  const selfReviewProduct = productDetails(
    rowWithSelfReflection
      ? rowWithSelfReflection.reviewCycle.selfReflectionProductName
      : ProductName.REVIEW,
  );
  const selfReviewProductName = `Self ${selfReviewProduct.lowerCase}`;

  // It's possible that there is a mix of cycles with different manager
  // reflection product names. Choose the most recent for the column heading.
  const rowWithManagerReflection = dashboardRows.find(
    (row) => row.reviewCycle.managerReflectionCycleEnabled,
  );
  const managerReviewProduct = productDetails(
    rowWithManagerReflection
      ? rowWithManagerReflection.reviewCycle.managerReviewProductName
      : ProductName.REVIEW,
  );
  const managerReviewProductName = `Manager ${managerReviewProduct.lowerCase}`;

  const showPeerFeedback = dashboardRows.some(
    (row) => row.reviewCycle.peerReviewCycleEnabled,
  );
  const showUpwardFeedback = dashboardRows.some(
    (row) => row.reviewCycle.upwardReviewCycleEnabled,
  );
  const showSelfReview = !!rowWithSelfReflection;
  const showManagerReview = !!rowWithManagerReflection;
  const subordinatesHaveUpwardRequestsSent = dashboardRows.some(
    (row) => row.upwardRequestsSent > 0,
  );

  const columns: ColumnsType<IReviewCycleUserDashboardRow> = [
    {
      title: 'Name',
      key: 'reviewCycleName',
      align: 'left',
      render: (_, row) => <Text>{row.reviewCycle.name}</Text>,
    },
    showPeerFeedback
      ? {
          title: 'Peers',
          key: 'peers',
          align: 'center',
          render: (_, row) =>
            row.reviewCycle.peerReviewCycleEnabled && (
              <PeerSelectionActionCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
                align={'center'}
              />
            ),
        }
      : null,
    showPeerFeedback
      ? {
          title: 'Feedback received',
          key: 'feedbackReceived',
          align: 'center',
          width: 180,
          render: (_, row) =>
            row.reviewCycle.peerReviewCycleEnabled && (
              <PeerFeedbackReceivedCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
              />
            ),
        }
      : null,
    showPeerFeedback
      ? {
          title: 'Feedback given',
          key: 'feedbackGiven',
          align: 'center',
          width: 150,
          render: (_, row) =>
            row.reviewCycle.peerReviewCycleEnabled && (
              <PeerFeedbackGivenCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
              />
            ),
        }
      : null,
    showUpwardFeedback && subordinatesHaveUpwardRequestsSent
      ? {
          title: 'Upward feedback',
          key: 'upwardfeedbackReceived',
          align: 'center',
          width: 180,
          render: (_, row) =>
            row.reviewCycle.upwardFeedbackEnabled && (
              <UpwardFeedbackReceivedCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
              />
            ),
        }
      : null,
    showSelfReview
      ? {
          title: selfReviewProductName,
          key: 'selfReflection',
          align: 'center',
          render: (_, row) =>
            row.reviewCycle.selfReflectionCycleEnabled && (
              <SelfReflectionCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
              />
            ),
        }
      : null,
    showManagerReview
      ? {
          title: managerReviewProductName,
          key: 'managerReflection',
          align: 'center',
          render: (_, row) =>
            row.reviewCycle.managerReflectionCycleEnabled && (
              <ManagerReflectionCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
              />
            ),
        }
      : null,
    !disableCycleRelease && (showPeerFeedback || showManagerReview)
      ? {
          title: 'Shared',
          key: 'shared',
          align: 'center',
          render: (_, row) =>
            ((row.reviewCycle.peerReviewCycleEnabled &&
              row.reviewCycle.peerReviewShareMode === ShareMode.SHARED) ||
              row.reviewCycle.managerReflectionCycleEnabled) && (
              <ReviewsSharedCell
                row={row}
                onChange={onChange}
                reviewCycle={row.reviewCycle}
                isAdminView={!isReport}
              />
            ),
        }
      : null,
  ].filter((item) => !!item) as ColumnsType<IReviewCycleUserDashboardRow>;
  return (
    <Column>
      <Table<IReviewCycleUserDashboardRow>
        columns={columns}
        dataSource={dashboardRows}
        pagination={false}
        locale={{
          emptyText: <Empty description={emptyText} />,
        }}
        rowKey="token"
        scroll={{
          x: true,
        }}
      />
    </Column>
  );
};
