import { Feature } from '@shared/features';
import { formatDate } from '@shared/formatDate';
import { ReflectionState, productDetails } from '@shared/reflections';
import {
  IReviewCycleParticipantSummary,
  ShareMode,
} from '@shared/review-cycles';
import { hasManager } from '@shared/users';
import { useResponsive } from '@web/app/responsive';
import { useFeature } from '@web/common/useFeature';
import { OpenRequestsLabel } from '@web/components/OpenRequestsLabel';
import { Grid, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectPeersButton } from '@web/notifications/SelectPeersButton';
import { Button } from 'antd';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { BorderedPane } from './BorderedPane';
import { Status } from './Status';
import { countCycleComponents } from './countCycleComponents';

interface IReviewCycleStatusProps {
  reviewCycleSummary: IReviewCycleParticipantSummary;
  onChange?: () => void;
}

export const IndividualReviewCycleStatus: React.FC<IReviewCycleStatusProps> = ({
  reviewCycleSummary,
  onChange,
}) => {
  const { isMobile } = useResponsive();
  const {
    peerReviewCycleEnabled,
    selfReflectionCycleEnabled,
    managerReflectionCycleEnabled,
  } = reviewCycleSummary.reviewCycle;
  const columnCount = countCycleComponents(reviewCycleSummary.reviewCycle);
  const columns = new Array<string>(columnCount).fill('1fr').join(' ');
  return (
    <Grid gap={12} columns={isMobile ? '1fr' : columns}>
      {peerReviewCycleEnabled && (
        <BorderedPane>
          <PeerReviewStatus
            reviewCycleSummary={reviewCycleSummary}
            onChange={onChange}
          />
        </BorderedPane>
      )}
      {selfReflectionCycleEnabled && (
        <BorderedPane>
          <SelfReviewStatus
            reviewCycleSummary={reviewCycleSummary}
            onChange={onChange}
          />
        </BorderedPane>
      )}
      {managerReflectionCycleEnabled && (
        <BorderedPane>
          <ManagerReviewStatus
            reviewCycleSummary={reviewCycleSummary}
            onChange={onChange}
          />
        </BorderedPane>
      )}
    </Grid>
  );
};

const PeerReviewStatus: React.FC<IReviewCycleStatusProps> = ({
  reviewCycleSummary,
  onChange,
}) => {
  const navigate = useNavigate();
  const { booleanValue: adminOnlyPeerSelection } = useFeature(
    Feature.ADMIN_ONLY_PEER_SELECTION,
  );
  const { peerReview, reviewCycle } = reviewCycleSummary;
  const title = 'Peer feedback';

  const hasSelectedPeers =
    peerReview?.selectedPeers.length > 0 ||
    peerReview?.approvedPeers.length > 0;

  const startDate = reviewCycle.peerSelectionStartDate;
  const endDate = reviewCycle.peerFeedbackEndDate;

  if (!peerReview) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text="Not started"
      />
    );
  }

  if (!hasSelectedPeers && !adminOnlyPeerSelection) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text={`Select peers to provide you feedback by ${formatDate(
          reviewCycle.peerSelectionEndDate,
        )}`}
        actions={
          <SelectPeersButton
            organizationToken={peerReview.organizationToken}
            peerReviewToken={peerReview.token}
            user={peerReview.user}
            maxPeersPerParticipant={
              reviewCycle?.peerReviewMaxPeersPerParticipant ?? 0
            }
            type="primary"
            style={{ width: 120 }}
            onChange={onChange}
          />
        }
      />
    );
  } else if (peerReview.feedbackReleasedDate) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text={`✅ Shared on ${formatDate(peerReview.feedbackReleasedDate)}`}
        actions={
          <Link
            to={`/cycles/${reviewCycle.token}/users/${peerReview.user.token}/feedback`}
          >
            <Button>View feedback</Button>
          </Link>
        }
      />
    );
  } else if (reviewCycleSummary.openTasksResponse?.tasks?.length > 0) {
    const handleViewFeedbackRequestsClicked = () => {
      // if this component exists on the review cycle page, which contains
      // an inline listing of open requests, then scroll to it
      // otherwise, navigate to the review cycle page
      // this approach isn't the best in that if someone removes id on the
      // in-page listing, the would unknowingly break this button 🤷
      // in the future, we should look to instead pass in a handler for when this
      // button is clicked in order to change behaviour depending on context
      const inPageRequestList = document.getElementById(
        'peer-feedback-requests',
      );
      if (inPageRequestList) {
        inPageRequestList.scrollIntoView();
      } else {
        navigate(`/cycles/${reviewCycle.token}`);
      }
    };

    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text={`Feedback is being collected.`}
        actions={
          <Button onClick={handleViewFeedbackRequestsClicked}>
            <Row gap={6}>
              <Text>View feedback requests</Text>
              <OpenRequestsLabel>
                {reviewCycleSummary.openTasksResponse.tasks.length}
              </OpenRequestsLabel>
            </Row>
          </Button>
        }
      />
    );
  }

  return (
    <Status
      title={title}
      startDate={startDate}
      endDate={endDate}
      text="✅ Your peers have been selected"
    >
      <Text>
        {reviewCycle.peerReviewShareMode === ShareMode.SHARED
          ? `Feedback will be collected and shared with you at the end of the cycle`
          : `Feedback will be collected and shared with your manager`}
      </Text>
    </Status>
  );
};

const SelfReviewStatus: React.FC<IReviewCycleStatusProps> = ({
  reviewCycleSummary,
}) => {
  const { selfReview, reviewCycle } = reviewCycleSummary;
  const selfReviewProduct = productDetails(
    reviewCycle.selfReflectionProductName,
  );
  const title = `Self ${selfReviewProduct.lowerCase}`;
  const startDate = reviewCycle.selfReviewStartDate;
  const endDate = reviewCycle.selfReviewEndDate;

  if (selfReview?.state === ReflectionState.SHARED) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text={`✅ Completed on ${formatDate(selfReview.sharedDate)}`}
        actions={
          <Link to={`/reflections/${selfReview.token}`}>
            <Button>View self {selfReviewProduct.lowerCase}</Button>
          </Link>
        }
      />
    );
  }

  if (reviewCycle.closedDate) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text="Incomplete"
      />
    );
  }
  if (!selfReview) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text="Not started"
      />
    );
  }

  const text = `You must complete your self ${
    selfReviewProduct.lowerCase
  } by ${formatDate(reviewCycle.selfReviewEndDate)}`;
  return (
    <Status
      title={title}
      startDate={startDate}
      endDate={endDate}
      text={text}
      actions={
        <Link to={`/reflections/${selfReview.token}/edit`}>
          <Button type="primary">
            Complete self {selfReviewProduct.lowerCase}
          </Button>
        </Link>
      }
    />
  );
};

const ManagerReviewStatus: React.FC<IReviewCycleStatusProps> = ({
  reviewCycleSummary,
}) => {
  const { participant, managerReview, reviewCycle } = reviewCycleSummary;

  const managerReviewProduct = productDetails(
    reviewCycle.managerReviewProductName,
  );
  const title = `Manager ${managerReviewProduct.lowerCase}`;
  const startDate = reviewCycle.managerReviewStartDate;
  const endDate = reviewCycle.managerReviewEndDate;

  if (!hasManager(participant)) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text="Not applicable"
      />
    );
  }
  if (!managerReview || managerReview.state !== ReflectionState.SHARED) {
    return (
      <Status
        title={title}
        startDate={startDate}
        endDate={endDate}
        text="Not shared"
      />
    );
  }

  return (
    <Status
      title={title}
      startDate={startDate}
      endDate={endDate}
      text={`✅ Shared on ${formatDate(managerReview.sharedDate)}`}
      actions={
        <Link to={`/reflections/${managerReview.token}`}>
          <Button>View manager {managerReviewProduct.lowerCase}</Button>
        </Link>
      }
    />
  );
};
