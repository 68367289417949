import { IEvent } from '@shared/events';
import { reportApiError } from '@web/app/ErrorBoundary';
import { DeepPartial } from 'typeorm';

export async function post<TBody, TResponse = TBody>(
  url: string,
  body?: Partial<TBody>,
): Promise<TResponse> {
  return await request<TResponse>(url, {
    method: 'POST',
    body: body !== undefined ? JSON.stringify(body) : null,
  });
}

export async function put<TBody, TResponse = TBody>(
  url: string,
  body?: Partial<TBody>,
): Promise<TResponse> {
  return await request<TResponse>(url, {
    method: 'PUT',
    body: body !== undefined ? JSON.stringify(body) : null,
  });
}

export async function patch<TBody, TResponse = TBody>(
  url: string,
  body?: DeepPartial<TBody>,
): Promise<TResponse> {
  return await request<TResponse>(url, {
    method: 'PATCH',
    body: body !== undefined ? JSON.stringify(body) : null,
  });
}

export async function del(url: string): Promise<undefined> {
  return await request<undefined>(url, {
    method: 'DELETE',
  });
}

export async function get<T>(url: string): Promise<T> {
  return await request<T>(url, {
    method: 'GET',
  });
}

export function trackEvent(event: Partial<IEvent>) {
  void post('/events', event);
}

export async function request<T>(
  url: string,
  requestInit: RequestInit,
): Promise<T | undefined> {
  const apiRoot = process.env.API_ROOT ?? '';
  const apiUrl = `${apiRoot}/api${url}`;
  const response = await fetch(apiUrl, {
    credentials: 'include',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    ...requestInit,
  });

  if (!response.ok) {
    const responseText = await response.text();
    reportApiError(
      requestInit.method as any,
      apiUrl,
      response.status,
      responseText,
    );

    if (responseText !== '') {
      let serverError;
      try {
        serverError = JSON.parse(responseText);
      } catch (error) {
        throw new ServerResponseError(response.status, response.statusText);
      }
      if (
        serverError?.statusCode ||
        serverError?.message ||
        serverError?.displayMessage
      ) {
        throw new ServerResponseError(
          serverError?.statusCode,
          serverError?.message,
          serverError?.displayMessage,
        );
      } else {
        throw new ServerResponseError(response.status, response.statusText);
      }
    }

    throw new ServerResponseError(response.status, 'An unknown error occurred');
  }

  const responseText = await response.text();
  if (responseText !== '') {
    return JSON.parse(responseText);
  }

  if (requestInit.method === 'GET') {
    throw new ServerResponseError(400, 'Bad response - missing response body');
  }
}

interface IAbortableRequest<T> {
  abort: () => void;
  response: () => Promise<T | undefined>;
}
export function abortableRequest<T>(
  url: string,
  requestInit: RequestInit,
): IAbortableRequest<T> {
  const abortController = new AbortController();
  const responsePromise = request<T>(url, {
    ...requestInit,
    signal: abortController.signal,
  });

  return {
    abort: () => {
      abortController.abort();
    },
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    response: () => responsePromise,
  };
}

export class ServerResponseError extends Error {
  constructor(
    public statusCode: number,
    public message: string,
    public displayMessage?: string,
  ) {
    super(message);
  }
}

export interface ServerSearchResponse<T> {
  results: T[];
  total: number;
}
