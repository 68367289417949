import { ReflectionToken } from '@shared/reflections';
import {
  DashboardRowFilter,
  GetManagerReviewResponse,
  IReviewCycle,
  IReviewCycleDashboardRow,
  IReviewCycleParticipantSummary,
  IReviewCycleStatus,
  IReviewCycleTeamSummary,
  IReviewCycleUserDashboardSummary,
  ReconcileManagerReviewsRequest,
  ReviewCycleBatchEventToken,
  ReviewCycleEventType,
  ReviewCycleToken,
  UpdateCycleReflectionRequest,
  UpdateCycleReflectionResponse,
  UpdateReviewCycleNotificationsRequest,
} from '@shared/review-cycles';
import { IAutoScheduledEventUpdate } from '@shared/scheduled_event';
import { UserToken } from '@shared/types';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function closeCycle(reviewCycleToken: ReviewCycleToken) {
  return post<{}>(`/review-cycles/${reviewCycleToken}/close`);
}

export function useGetUserManagerReview(
  reviewCycleToken: ReviewCycleToken,
  receiverToken: UserToken,
) {
  const url = stringifyUrl({
    url: `/review-cycles/${reviewCycleToken}/reflection/${receiverToken}`,
    query: {},
  });

  return useApi<GetManagerReviewResponse>(url);
}

export function getUserManagerReview(
  reviewCycleToken: ReviewCycleToken,
  receiverToken: UserToken,
) {
  const url = stringifyUrl({
    url: `/review-cycles/${reviewCycleToken}/reflection/${receiverToken}`,
    query: {},
  });

  return get<GetManagerReviewResponse>(url);
}

export function createCycle(name: string) {
  return post<{ name: string }, IReviewCycle>(`/review-cycles`, { name: name });
}

export function runCycleAgain(reviewCycleToken: ReviewCycleToken) {
  return post<{}, IReviewCycle>(`/review-cycles/${reviewCycleToken}/rerun`);
}

export function updateCycleReflection(
  token: ReflectionToken,
  request: UpdateCycleReflectionRequest,
) {
  return put<UpdateCycleReflectionRequest, UpdateCycleReflectionResponse>(
    `/review-cycles/reflections/${token}`,
    request,
  );
}

export function useGetLatestParticipantSummary(userToken?: UserToken) {
  const url = stringifyUrl({
    url: `/review-cycles/latest`,
    query: {
      userToken: userToken,
    },
  });

  return useApi<false | IReviewCycleParticipantSummary>(url);
}

export function getLatestParticipantSummary(userToken?: UserToken) {
  const url = stringifyUrl({
    url: `/review-cycles/latest`,
    query: {
      userToken: userToken,
    },
  });

  return get<false | IReviewCycleParticipantSummary>(url);
}

export function reconcileManagerReviews(
  reviewCycleToken: ReviewCycleToken,
  request: ReconcileManagerReviewsRequest,
) {
  return post<ReconcileManagerReviewsRequest>(
    `/review-cycles/${reviewCycleToken}/reviews/reconcile`,
    request,
  );
}

export function useAdminTeamDashboard(reviewCycleToken: ReviewCycleToken) {
  const url = stringifyUrl({
    url: `/review-cycles/${reviewCycleToken}/adminTeamDashboard`,
    query: {},
  });

  return useApi<Record<`u_${string}`, IReviewCycleTeamSummary>>(url);
}

export function adminTeamDashboard(reviewCycleToken: ReviewCycleToken) {
  const url = stringifyUrl({
    url: `/review-cycles/${reviewCycleToken}/adminTeamDashboard`,
    query: {},
  });

  return get<Record<`u_${string}`, IReviewCycleTeamSummary>>(url);
}

export function update(
  reviewCycleToken: ReviewCycleToken,
  reviewCycle: IReviewCycle,
) {
  return patch<IReviewCycle>(`/review-cycles/${reviewCycleToken}`, reviewCycle);
}

export function startReviewCycle(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/start`);
}

export function sendCycleIntroduction(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/send-cycle-introduction`);
}

export function startPeerReviewCycle(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/peerReviewCycle`);
}

export function startUpwardReviewCycle(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/upwardReviewCycle`);
}

export function sendPeerApprovalReminders(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/send-peer-approval-reminders`,
  );
}

export function sendPeerSelectionReminders(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/send-peer-selection-reminders`,
  );
}

export function sendPeerReviewFeedbackRequests(
  reviewCycleToken: ReviewCycleToken,
) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/peerFeedbackRequests`);
}

export function sendUpwardReviewFeedbackRequests(
  reviewCycleToken: ReviewCycleToken,
) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/upwardFeedbackRequests`);
}

export function sendUserPeerReviewFeedbackRequests(
  reviewCycleToken: ReviewCycleToken,
  participant: UserToken,
) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/participants/${participant}/send-feedback-requests`,
  );
}

export function sendPeerFeedbackRequestReminders(
  reviewCycleToken: ReviewCycleToken,
) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/peer-feedback-request-reminders`,
  );
}

export function sendUpwardFeedbackRequestReminders(
  reviewCycleToken: ReviewCycleToken,
  timing: 'in-progress' | 'due' | 'overdue',
) {
  return put<{ timing: 'in-progress' | 'due' | 'overdue' }>(
    `/review-cycles/${reviewCycleToken}/upward-feedback-request-reminders`,
    { timing: timing },
  );
}

export function sendManagerReflectionReminder(
  reviewCycleToken: ReviewCycleToken,
) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/manager-reflection-reminder`,
  );
}

export function startManagerReflectionCycle(
  reviewCycleToken: ReviewCycleToken,
) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/manager-reflection-cycle`);
}

export function reviewCycleDashboard(
  reviewCycleToken: ReviewCycleToken,
  skip: number,
  limit: number,
  filter?: DashboardRowFilter,
) {
  return post<{
    skip: number;
    limit: number;
    filter: DashboardRowFilter;
  }>(`/review-cycles/${reviewCycleToken}/admin/dashboard/search`, {
    skip: skip,
    limit: limit,
    filter: filter,
  });
}

export function useReviewCycleAdminStatus(reviewCycleToken: ReviewCycleToken) {
  const url = stringifyUrl({
    url: `/review-cycles/${reviewCycleToken}/admin/status`,
    query: {},
  });

  return useApi<IReviewCycleStatus>(url);
}

export function reviewCycleAdminStatus(reviewCycleToken: ReviewCycleToken) {
  const url = stringifyUrl({
    url: `/review-cycles/${reviewCycleToken}/admin/status`,
    query: {},
  });

  return get<IReviewCycleStatus>(url);
}

export function deleteReviewCycleNotification(
  reviewCycleToken: ReviewCycleToken,
  type: ReviewCycleEventType,
) {
  return del(`/review-cycles/${reviewCycleToken}/notifications/${type}`);
}

export function editReviewCycleNotifications(
  reviewCycleToken: ReviewCycleToken,
  request: UpdateReviewCycleNotificationsRequest,
) {
  return put<UpdateReviewCycleNotificationsRequest>(
    `/review-cycles/${reviewCycleToken}/notifications`,
    request,
  );
}

export function deleteReviewCycleBatchEvent(
  reviewCycleToken: ReviewCycleToken,
  reviewCycleBatchEventToken: ReviewCycleBatchEventToken,
) {
  return del(
    `/review-cycles/${reviewCycleToken}/batch-events/${reviewCycleBatchEventToken}`,
  );
}

export function updateReviewCycleBatchEvent(
  reviewCycleToken: ReviewCycleToken,
  reviewCycleBatchEventToken: ReviewCycleBatchEventToken,
  events: ReviewCycleEventType[],
  schedule: IAutoScheduledEventUpdate,
) {
  return put<{
    events: ReviewCycleEventType[];
    schedule: IAutoScheduledEventUpdate;
  }>(
    `/review-cycles/${reviewCycleToken}/batch-events/${reviewCycleBatchEventToken}`,
    {
      events: events,
      schedule: schedule,
    },
  );
}

export function createReviewCycleBatchEvent(
  reviewCycleToken: ReviewCycleToken,
  events: ReviewCycleEventType[],
  schedule: IAutoScheduledEventUpdate,
) {
  return post<{
    events: ReviewCycleEventType[];
    schedule: IAutoScheduledEventUpdate;
  }>(`/review-cycles/${reviewCycleToken}/batch-events`, {
    events: events,
    schedule: schedule,
  });
}

export function sendSelfReflectionReminder(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/self-reflection-reminder`);
}

export function startSelfReflectionCycle(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(`/review-cycles/${reviewCycleToken}/self-reflection-cycle`);
}

export function addParticipants(
  reviewCycleToken: ReviewCycleToken,
  participant: UserToken,
  includeTeam: boolean,
) {
  return post<{
    participant: `u_${string}`;
    includeTeam: boolean;
  }>(`/review-cycles/${reviewCycleToken}/participants`, {
    participant: participant,
    includeTeam: includeTeam,
  });
}

export function useGetUserDashboard(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/review-cycles/user/${userToken}/dashboard`,
    query: {},
  });

  return useApi<IReviewCycleUserDashboardSummary>(url);
}

export function getUserDashboard(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/review-cycles/user/${userToken}/dashboard`,
    query: {},
  });

  return get<IReviewCycleUserDashboardSummary>(url);
}

export function useManagerWidgetDetails(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/review-cycles/:reviewCycleToken/participants/${userToken}/widget`,
    query: {},
  });

  return useApi<{
    reviewCycle?: IReviewCycle;
    dashboardRow?: IReviewCycleDashboardRow;
    isReport?: boolean;
  }>(url);
}

export function managerWidgetDetails(userToken: UserToken) {
  const url = stringifyUrl({
    url: `/review-cycles/:reviewCycleToken/participants/${userToken}/widget`,
    query: {},
  });

  return get<{
    reviewCycle?: IReviewCycle;
    dashboardRow?: IReviewCycleDashboardRow;
    isReport?: boolean;
  }>(url);
}

export function removeParticipant(
  reviewCycleToken: ReviewCycleToken,
  userToken: UserToken,
) {
  return del(`/review-cycles/${reviewCycleToken}/participants/${userToken}`);
}

export function releaseFeedback(
  reviewCycleToken: ReviewCycleToken,
  participantToken: UserToken,
) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/participants/${participantToken}/release-feedback`,
  );
}

export function sendReleaseReviewsReminder(reviewCycleToken: ReviewCycleToken) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/send-release-reviews-reminders`,
  );
}

export function mockSelfReview(
  reviewCycleToken: ReviewCycleToken,
  userToken: UserToken,
) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/participants/${userToken}/mockSelfReview`,
  );
}

export function mockManagerReview(
  reviewCycleToken: ReviewCycleToken,
  userToken: UserToken,
) {
  return put<{}>(
    `/review-cycles/${reviewCycleToken}/participants/${userToken}/mockManagerReview`,
  );
}
