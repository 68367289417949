import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { updateSurveyName } from '@client/SurveyCyclesClient';
import { formatDate } from '@shared/formatDate';
import { ISurveyCycle, SurveyTypeLabels } from '@shared/surveys';
import { AdminPageContent } from '@web/admin/AdminPageContent';
import { del, post, put } from '@web/common/api';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { BackLink } from '@web/components/BackButton';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { BorderedPane } from '@web/review-cycles/BorderedPane';
import { Button, Dropdown, MenuProps, Tooltip, message } from 'antd';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AdminSurveyTabs } from './AdminSurveyTabs';
import { InlineInputTitle } from './InlineInputTitle';

export const AdminSurveyContent: React.FC<{
  surveyCycle: ISurveyCycle;
  onChange?: () => void;
  children: React.ReactNode;
}> = ({ surveyCycle, children, onChange }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const navigate = useNavigate();

  const handleStartSurvey = async () => {
    const userConfirmed = await confirm(
      `This will send notifications to all participants. Are you sure you want to start this ${
        SurveyTypeLabels[surveyCycle.type]
      }?`,
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await put(`/survey-cycles/${surveyCycle.token}/start`);
      onChange?.();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleEndSurvey = async () => {
    const userConfirmed = await confirm(
      `No new submissions will be allowed once the ${
        SurveyTypeLabels[surveyCycle.type]
      } has ended. Are you sure you want to end this ${
        SurveyTypeLabels[surveyCycle.type]
      }?`,
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await put(`/survey-cycles/${surveyCycle.token}/end`);
      onChange?.();
      void message.success('Survey ended');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleRemoveSurvey = async () => {
    const userConfirmed = await confirm(
      `Are you sure you want to remove this ${
        SurveyTypeLabels[surveyCycle.type]
      }?`,
    );
    if (!userConfirmed) {
      return;
    }

    try {
      await del(`/survey-cycles/${surveyCycle.token}`);
      onChange?.();
      void message.success('Survey removed');
      navigate(-1);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleRunAgain = async () => {
    const userConfirmed = await confirm(
      `This will duplicate the ${
        SurveyTypeLabels[surveyCycle.type]
      } and all of its configuration, sections, and participants.`,
    );
    if (!userConfirmed) {
      return;
    }
    try {
      const newSurveyCycle = await post<ISurveyCycle>(
        `/survey-cycles/${surveyCycle.token}/rerun`,
      );
      navigate(`/admin/surveys/${newSurveyCycle.token}/settings`);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleNameSave = async (newName: string) => {
    await updateSurveyName(surveyCycle.token, newName);
  };

  let surveyStartBlockerReason: string | undefined;
  if (surveyCycle.questions.length === 0) {
    surveyStartBlockerReason = `${capitalize(
      SurveyTypeLabels[surveyCycle.type],
    )} has no sections`;
  } else if (surveyCycle.participants.length === 0) {
    surveyStartBlockerReason = `${capitalize(
      SurveyTypeLabels[surveyCycle.type],
    )} has no participants`;
  }

  const ItemLabel = styled(Text)`
    line-height: 40px;
  `;

  const items: MenuProps['items'] = [
    {
      key: 'export-ratings',
      label: (
        <a
          href={`${process.env.API_ROOT ?? ''}/api/survey-cycles/${
            surveyCycle.token
          }/export/ratings`}
          target="_blank"
          rel="noreferrer"
        >
          <ItemLabel>Export Rating Responses</ItemLabel>
        </a>
      ),
      disabled: !surveyCycle.endedDate,
    },
    {
      key: 'export-qualitative',
      label: (
        <a
          href={`${process.env.API_ROOT ?? ''}/api/survey-cycles/${
            surveyCycle.token
          }/export/qualitative`}
          target="_blank"
          rel="noreferrer"
        >
          <ItemLabel>Export Qualitative Responses</ItemLabel>
        </a>
      ),
      disabled: !surveyCycle.endedDate,
    },
    surveyCycle.anonymous
      ? null
      : {
          key: 'export-individual',
          label: (
            <a
              href={`${process.env.API_ROOT ?? ''}/api/survey-cycles/${
                surveyCycle.token
              }/export/individual`}
              target="_blank"
              rel="noreferrer"
            >
              <ItemLabel>Export Individual Responses</ItemLabel>
            </a>
          ),
          disabled: !surveyCycle.endedDate,
        },
    !surveyCycle.correlationToken
      ? null
      : {
          key: 'export-trends',
          label: (
            <a
              href={`${process.env.API_ROOT ?? ''}/api/survey-cycles/${
                surveyCycle.token
              }/export/trends`}
              target="_blank"
              rel="noreferrer"
            >
              <ItemLabel>Export Rating Trends</ItemLabel>
            </a>
          ),
          disabled: !surveyCycle.endedDate,
        },
  ];

  return (
    <AdminPageContent>
      <Column gap={6}>
        <Row>
          <BackLink to={`/admin/surveys`} />
        </Row>
        <Row gap={6}>
          <InlineInputTitle value={surveyCycle?.name} onSave={handleNameSave} />
          <GrowingSpacer />
          {surveyCycle.endedDate ? (
            <Button type="primary" onClick={handleRunAgain}>
              Run {SurveyTypeLabels[surveyCycle.type]} again
            </Button>
          ) : surveyCycle.startedDate ? (
            <Button type="primary" onClick={handleEndSurvey}>
              End {SurveyTypeLabels[surveyCycle.type]}
            </Button>
          ) : surveyStartBlockerReason !== undefined ? (
            <Tooltip title={surveyStartBlockerReason}>
              <Button type="primary" disabled>
                Start {SurveyTypeLabels[surveyCycle.type]}
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" onClick={handleStartSurvey}>
              Start {SurveyTypeLabels[surveyCycle.type]}
            </Button>
          )}
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button>
              <MenuOutlined />
            </Button>
          </Dropdown>
          {!surveyCycle.startedDate && (
            <Button onClick={handleRemoveSurvey}>
              <DeleteOutlined />
            </Button>
          )}
        </Row>
      </Column>
      {surveyCycle.endedDate ? (
        <Text>
          <strong>Status:</strong> Ended on {formatDate(surveyCycle.endedDate)}
        </Text>
      ) : surveyCycle.startedDate ? (
        <Text>
          <strong>Status:</strong> Started on{' '}
          {formatDate(surveyCycle.startedDate)}
        </Text>
      ) : (
        <Text>
          <strong>Status:</strong> Not started
        </Text>
      )}
      <Column>
        <AdminSurveyTabs surveyCycle={surveyCycle} />
        <BorderedPane style={{ borderTopLeftRadius: 0 }}>
          {children}
        </BorderedPane>
      </Column>
      {contextHolder}
    </AdminPageContent>
  );
};
