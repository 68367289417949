import { dateString } from '@shared/dateString';
import { Feature } from '@shared/features';
import { ProductName, productDetails } from '@shared/reflections';
import { IReviewCycle, ShareMode } from '@shared/review-cycles';
import { patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { SelectDate, SelectDateRange } from '@web/components/SelectDate';
import { SelectHour } from '@web/components/SelectDateHour';
import { Column, Row, WrapRow } from '@web/components/layout';
import { Text } from '@web/components/text';
import { Header3 } from '@web/components/typography';
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Select,
  Typography,
  message,
} from 'antd';
import { addDays, format } from 'date-fns';
import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';

interface IReviewCycleConfig {
  name: string;
  peerReviewCycleEnabled: boolean;
  peerSelectionStartDate?: string;
  peerSelectionEndDate?: string;
  peerApprovalStartDate?: string;
  peerApprovalEndDate?: string;
  peerFeedbackStartDate?: string;
  peerFeedbackEndDate?: string;
  peerReviewShareMode: ShareMode;
  peerReviewMaxPeersPerParticipant: number;
  peerReviewMaxRequestsPerPeer: number;
  upwardReviewCycleEnabled: boolean;
  upwardFeedbackStartDate?: string;
  upwardFeedbackEndDate?: string;
  selfReflectionCycleEnabled: boolean;
  selfReflectionProductName: ProductName;
  selfReviewStartDate?: string;
  selfReviewEndDate?: string;
  managerReviewProductName: ProductName;
  managerReflectionCycleEnabled: boolean;
  managerReviewStartDate?: string;
  managerReviewEndDate?: string;
  sharedByDate?: string;
  notificationHour?: number;
}

export const EditReviewCycleForm: React.FC<{
  onSave: (savedReviewCycle: IReviewCycle) => void;
  onCancel?: () => void;
  reviewCycleToken: string;
  modalButtonMode?: boolean;
}> = ({ onSave, onCancel, reviewCycleToken, modalButtonMode }) => {
  const { booleanValue: upwardFeedbackEnabled } = useFeature(
    Feature.UPWARD_FEEDBACK_ENABLED,
  );
  const { booleanValue: enableNewCycleScheduling } = useFeature(
    Feature.ENABLE_NEW_CYCLE_SCHEDULING,
  );
  const isEdit = !!reviewCycleToken;
  const { data: reviewCycle } = useApi<IReviewCycle>(
    isEdit ? `/review-cycles/${reviewCycleToken}` : null,
  );
  const [reviewCycleConfig, setReviewCycleConfig] =
    React.useState<IReviewCycleConfig>(
      getDefaultConfig(enableNewCycleScheduling) as any,
    );
  React.useEffect(() => {
    if (reviewCycle) {
      const defaultSchedule = getDefaultConfig(enableNewCycleScheduling);
      const updatedConfig: IReviewCycleConfig = {
        name: reviewCycle.name,
        peerReviewCycleEnabled: reviewCycle.peerReviewCycleEnabled,
        peerSelectionStartDate: dateString(
          reviewCycle.peerSelectionStartDate ??
            defaultSchedule.peerSelectionStartDate,
        ),
        peerSelectionEndDate: dateString(
          reviewCycle.peerSelectionEndDate ??
            defaultSchedule.peerSelectionEndDate,
        ),
        peerApprovalStartDate: dateString(
          reviewCycle.peerApprovalStartDate ??
            defaultSchedule.peerApprovalStartDate,
        ),
        peerApprovalEndDate: dateString(
          reviewCycle.peerApprovalEndDate ??
            defaultSchedule.peerApprovalEndDate,
        ),
        peerFeedbackStartDate: dateString(
          reviewCycle.peerFeedbackStartDate ??
            defaultSchedule.peerFeedbackStartDate,
        ),
        peerFeedbackEndDate: dateString(
          reviewCycle.peerFeedbackEndDate ??
            defaultSchedule.peerFeedbackEndDate,
        ),
        peerReviewShareMode: reviewCycle.peerReviewShareMode,
        peerReviewMaxPeersPerParticipant:
          reviewCycle.peerReviewMaxPeersPerParticipant,
        peerReviewMaxRequestsPerPeer: reviewCycle.peerReviewMaxRequestsPerPeer,
        upwardReviewCycleEnabled: reviewCycle.upwardReviewCycleEnabled,
        upwardFeedbackStartDate: dateString(
          reviewCycle.upwardFeedbackStartDate ??
            defaultSchedule.upwardFeedbackStartDate,
        ),
        upwardFeedbackEndDate: dateString(
          reviewCycle.upwardFeedbackEndDate ??
            defaultSchedule.upwardFeedbackEndDate,
        ),
        selfReflectionCycleEnabled: reviewCycle.selfReflectionCycleEnabled,
        selfReviewStartDate: dateString(
          reviewCycle.selfReviewStartDate ??
            defaultSchedule.selfReviewStartDate,
        ),
        selfReviewEndDate: dateString(
          reviewCycle.selfReviewEndDate ?? defaultSchedule.selfReviewEndDate,
        ),
        selfReflectionProductName: reviewCycle.selfReflectionProductName,
        managerReviewProductName: reviewCycle.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycle.managerReflectionCycleEnabled,
        managerReviewStartDate: dateString(
          reviewCycle.managerReviewStartDate ??
            defaultSchedule.managerReviewStartDate,
        ),
        managerReviewEndDate: dateString(
          reviewCycle.managerReviewEndDate ??
            defaultSchedule.managerReviewEndDate,
        ),
        sharedByDate: reviewCycle.sharedByDate
          ? dateString(reviewCycle.sharedByDate)
          : undefined,
        notificationHour: reviewCycle.notificationHour,
      };
      setReviewCycleConfig(updatedConfig);
    }
  }, [reviewCycle]);

  const handleSave = async () => {
    try {
      const body: IReviewCycleConfig = {
        name: reviewCycleConfig.name,
        peerReviewCycleEnabled: reviewCycleConfig.peerReviewCycleEnabled,
        peerSelectionStartDate: reviewCycleConfig.peerSelectionStartDate
          ? `${reviewCycleConfig.peerSelectionStartDate}T23:59:59.999Z`
          : null,
        peerSelectionEndDate: reviewCycleConfig.peerSelectionEndDate
          ? `${reviewCycleConfig.peerSelectionEndDate}T23:59:59.999Z`
          : null,
        peerApprovalStartDate: reviewCycleConfig.peerApprovalStartDate
          ? `${reviewCycleConfig.peerApprovalStartDate}T23:59:59.999Z`
          : null,
        peerApprovalEndDate: reviewCycleConfig.peerApprovalEndDate
          ? `${reviewCycleConfig.peerApprovalEndDate}T23:59:59.999Z`
          : null,
        peerFeedbackStartDate: reviewCycleConfig.peerFeedbackStartDate
          ? `${reviewCycleConfig.peerFeedbackStartDate}T23:59:59.999Z`
          : null,
        peerFeedbackEndDate: reviewCycleConfig.peerFeedbackEndDate
          ? `${reviewCycleConfig.peerFeedbackEndDate}T23:59:59.999Z`
          : null,
        peerReviewShareMode: reviewCycleConfig.peerReviewShareMode,
        peerReviewMaxPeersPerParticipant:
          reviewCycleConfig.peerReviewMaxPeersPerParticipant,
        peerReviewMaxRequestsPerPeer:
          reviewCycleConfig.peerReviewMaxRequestsPerPeer,
        upwardReviewCycleEnabled: reviewCycleConfig.upwardReviewCycleEnabled,
        upwardFeedbackStartDate: reviewCycleConfig.upwardFeedbackStartDate
          ? `${reviewCycleConfig.upwardFeedbackStartDate}T23:59:59.999Z`
          : null,
        upwardFeedbackEndDate: reviewCycleConfig.upwardFeedbackEndDate
          ? `${reviewCycleConfig.upwardFeedbackEndDate}T23:59:59.999Z`
          : null,
        selfReflectionCycleEnabled:
          reviewCycleConfig.selfReflectionCycleEnabled,
        selfReviewStartDate: reviewCycleConfig.selfReviewStartDate
          ? `${reviewCycleConfig.selfReviewStartDate}T23:59:59.999Z`
          : null,
        selfReviewEndDate: reviewCycleConfig.selfReviewEndDate
          ? `${reviewCycleConfig.selfReviewEndDate}T23:59:59.999Z`
          : null,
        selfReflectionProductName: reviewCycleConfig.selfReflectionProductName,
        managerReviewProductName: reviewCycleConfig.managerReviewProductName,
        managerReflectionCycleEnabled:
          reviewCycleConfig.managerReflectionCycleEnabled,
        managerReviewStartDate: reviewCycleConfig.managerReviewStartDate
          ? `${reviewCycleConfig.managerReviewStartDate}T23:59:59.999Z`
          : null,
        managerReviewEndDate: reviewCycleConfig.managerReviewEndDate
          ? `${reviewCycleConfig.managerReviewEndDate}T23:59:59.999Z`
          : null,
        sharedByDate: reviewCycleConfig.sharedByDate
          ? `${reviewCycleConfig.sharedByDate}T23:59:59.999Z`
          : null,
        notificationHour: reviewCycleConfig.notificationHour, // Explicitly pass `null` to clear out the `notificationHour`.
      };
      let savedReviewCycle: IReviewCycle;
      if (isEdit) {
        savedReviewCycle = await patch<IReviewCycleConfig, IReviewCycle>(
          `/review-cycles/${reviewCycleToken}`,
          body,
        );
      } else {
        savedReviewCycle = await post<IReviewCycleConfig, IReviewCycle>(
          '/review-cycles',
          body,
        );
      }

      void message.success('Success');
      onSave(savedReviewCycle);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDateUpdated =
    (property: keyof IReviewCycleConfig) => (date?: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [property]: date,
      });
    };
  const handleDateRangeUpdated =
    (
      startProperty: keyof IReviewCycleConfig,
      endProperty: keyof IReviewCycleConfig,
    ) =>
    (startDate: string, endDate: string) => {
      setReviewCycleConfig({
        ...reviewCycleConfig,
        [startProperty]: startDate,
        [endProperty]: endDate,
      });
    };
  const handleSetPeerReviewEnabled = (peerReviewCycleEnabled: boolean) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      peerReviewCycleEnabled,
    });
  };
  const handleSetPeerReviewShareMode = (peerReviewShareMode: ShareMode) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      peerReviewShareMode,
    });
  };
  const handleSetPeerReviewMaxPeersPerParticipant = (
    peerReviewMaxPeersPerParticipant: number,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      peerReviewMaxPeersPerParticipant,
    });
  };
  const handleSetPeerReviewMaxRequestsPerPeer = (
    peerReviewMaxRequestsPerPeer: number,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      peerReviewMaxRequestsPerPeer,
    });
  };
  const handleSetSelfReflectionCycleEnabled = (
    selfReflectionCycleEnabled: boolean,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      selfReflectionCycleEnabled,
    });
  };

  const handleSetSelfReflectionProductName = (
    selfReflectionProductName: ProductName,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      selfReflectionProductName,
    });
  };

  const handleSetManagerReviewProductName = (
    managerReviewProductName: ProductName,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      managerReviewProductName,
    });
  };

  const handleSetManagerReflectionCycleEnabled = (
    managerReflectionCycleEnabled: boolean,
  ) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      managerReflectionCycleEnabled,
    });
  };

  const handleNotificationHourChanged = (notificationHour: number) => {
    setReviewCycleConfig({
      ...reviewCycleConfig,
      notificationHour,
    });
  };

  const canSave =
    !!reviewCycleConfig.name && !!reviewCycleConfig.notificationHour;

  const upwardFeedbackAllowed =
    !!reviewCycle.upwardReviewQuestionSetToken && upwardFeedbackEnabled;

  return (
    <Column gap={24}>
      <Column gap={12} style={{ alignItems: 'flex-start', width: 794 }}>
        <Column>
          <Text>Name</Text>
          <Input
            style={{ width: 350 }}
            value={reviewCycleConfig.name}
            onChange={(e) => {
              setReviewCycleConfig({
                ...reviewCycleConfig,
                name: e.target.value,
              });
            }}
          />
        </Column>
        {!enableNewCycleScheduling && (
          <Column>
            <Text>Notification Time</Text>
            <SelectHour
              hour={reviewCycleConfig.notificationHour}
              onHourChanged={handleNotificationHourChanged}
            />
          </Column>
        )}
        <Divider style={{ margin: '6px 0' }} />
        <Column gap={12}>
          <Row gap={12}>
            <Header3>Peer Reviews</Header3>
            <Checkbox
              checked={reviewCycleConfig.peerReviewCycleEnabled}
              defaultChecked={reviewCycleConfig.peerReviewCycleEnabled}
              onChange={(event) => {
                handleSetPeerReviewEnabled(event.target.checked);
              }}
            ></Checkbox>
          </Row>
          {reviewCycleConfig.peerReviewCycleEnabled && (
            <>
              {!enableNewCycleScheduling && (
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <Text>Peer selection</Text>
                    <SelectDateRange
                      start={reviewCycleConfig.peerSelectionStartDate}
                      end={reviewCycleConfig.peerSelectionEndDate}
                      onChange={handleDateRangeUpdated(
                        'peerSelectionStartDate',
                        'peerSelectionEndDate',
                      )}
                      allowClear={false}
                    />
                  </Column>
                  <Column style={{ width: 250 }}>
                    <Text>Peer approvals</Text>
                    <SelectDateRange
                      start={reviewCycleConfig.peerApprovalStartDate}
                      end={reviewCycleConfig.peerApprovalEndDate}
                      onChange={handleDateRangeUpdated(
                        'peerApprovalStartDate',
                        'peerApprovalEndDate',
                      )}
                      allowClear={false}
                    />
                  </Column>
                  <Column style={{ width: 250 }}>
                    <Text>Peer feedback</Text>
                    <SelectDateRange
                      start={reviewCycleConfig.peerFeedbackStartDate}
                      end={reviewCycleConfig.peerFeedbackEndDate}
                      onChange={handleDateRangeUpdated(
                        'peerFeedbackStartDate',
                        'peerFeedbackEndDate',
                      )}
                      allowClear={false}
                    />
                  </Column>
                </WrapRow>
              )}
              <WrapRow gap={12}>
                <Column style={{ width: 380 }}>
                  <Text>Feedback Visibility</Text>
                  <SelectReviewShareMode
                    value={reviewCycleConfig.peerReviewShareMode}
                    onChange={handleSetPeerReviewShareMode}
                  />
                </Column>
                <Column style={{ width: 190 }}>
                  <Text>Max Peers Per Participant</Text>
                  <SelectMax
                    value={reviewCycleConfig.peerReviewMaxPeersPerParticipant}
                    onChange={handleSetPeerReviewMaxPeersPerParticipant}
                  />
                </Column>
                <Column style={{ width: 190 }}>
                  <Text>Max Requests Per Peer</Text>
                  <SelectMax
                    value={reviewCycleConfig.peerReviewMaxRequestsPerPeer}
                    onChange={handleSetPeerReviewMaxRequestsPerPeer}
                  />
                </Column>
              </WrapRow>
            </>
          )}
        </Column>
        {upwardFeedbackAllowed && (
          <>
            <Divider style={{ margin: '6px 0' }} />
            <Column gap={12}>
              <Row gap={12}>
                <Header3>Upward Feedback</Header3>
                <Checkbox
                  checked={reviewCycleConfig.upwardReviewCycleEnabled}
                  defaultChecked={reviewCycleConfig.upwardReviewCycleEnabled}
                  onChange={(event) => {
                    setReviewCycleConfig({
                      ...reviewCycleConfig,
                      upwardReviewCycleEnabled: event.target.checked,
                    });
                  }}
                />
              </Row>
              {reviewCycleConfig.upwardReviewCycleEnabled &&
                !enableNewCycleScheduling && (
                  <WrapRow gap={12}>
                    <Column style={{ width: 250 }}>
                      <Text>Schedule</Text>
                      <SelectDateRange
                        start={reviewCycleConfig.upwardFeedbackStartDate}
                        end={reviewCycleConfig.upwardFeedbackEndDate}
                        onChange={handleDateRangeUpdated(
                          'upwardFeedbackStartDate',
                          'upwardFeedbackEndDate',
                        )}
                        allowClear={false}
                      />
                    </Column>
                  </WrapRow>
                )}
            </Column>
          </>
        )}
        <Divider style={{ margin: '6px 0' }} />
        <Column gap={12}>
          <Row gap={12}>
            <Header3>
              Self{' '}
              {pluralize(
                productDetails(reviewCycleConfig.selfReflectionProductName)
                  .titleCase,
              )}
            </Header3>
            <Checkbox
              checked={reviewCycleConfig.selfReflectionCycleEnabled}
              defaultChecked={reviewCycleConfig.selfReflectionCycleEnabled}
              onChange={(event) => {
                handleSetSelfReflectionCycleEnabled(event.target.checked);
              }}
            ></Checkbox>
          </Row>
          {reviewCycleConfig.selfReflectionCycleEnabled && (
            <WrapRow gap={12}>
              <Column style={{ width: 120 }}>
                <Text>Name</Text>
                <SelectReviewProductName
                  value={reviewCycleConfig.selfReflectionProductName}
                  onChange={handleSetSelfReflectionProductName}
                />
              </Column>
              {!enableNewCycleScheduling && (
                <Column style={{ width: 250 }}>
                  <Text>Schedule</Text>
                  <SelectDateRange
                    start={reviewCycleConfig.selfReviewStartDate}
                    end={reviewCycleConfig.selfReviewEndDate}
                    onChange={handleDateRangeUpdated(
                      'selfReviewStartDate',
                      'selfReviewEndDate',
                    )}
                    allowClear={false}
                  />
                </Column>
              )}
            </WrapRow>
          )}
        </Column>
        <Divider style={{ margin: '6px 0' }} />
        <Column gap={12}>
          <Row gap={12}>
            <Header3>
              Manager{' '}
              {pluralize(
                productDetails(reviewCycleConfig.managerReviewProductName)
                  .titleCase,
              )}
            </Header3>
            <Checkbox
              checked={reviewCycleConfig.managerReflectionCycleEnabled}
              defaultChecked={reviewCycleConfig.managerReflectionCycleEnabled}
              onChange={(event) => {
                handleSetManagerReflectionCycleEnabled(event.target.checked);
              }}
            />
          </Row>
          {reviewCycleConfig.managerReflectionCycleEnabled && (
            <>
              <WrapRow gap={12}>
                <Column style={{ width: 120 }}>
                  <Text>Name</Text>
                  <SelectReviewProductName
                    value={reviewCycleConfig.managerReviewProductName}
                    onChange={handleSetManagerReviewProductName}
                  />
                </Column>
              </WrapRow>
              {!enableNewCycleScheduling && (
                <WrapRow gap={12}>
                  <Column style={{ width: 250 }}>
                    <Text>Schedule</Text>
                    <SelectDateRange
                      start={reviewCycleConfig.managerReviewStartDate}
                      end={reviewCycleConfig.managerReviewEndDate}
                      onChange={handleDateRangeUpdated(
                        'managerReviewStartDate',
                        'managerReviewEndDate',
                      )}
                      allowClear={false}
                    />
                  </Column>
                  <Column style={{ width: 250 }}>
                    <Text>Shared By</Text>
                    <SelectDate
                      value={reviewCycleConfig.sharedByDate}
                      onChange={handleDateUpdated('sharedByDate')}
                    />
                  </Column>
                </WrapRow>
              )}
            </>
          )}
        </Column>
      </Column>
      <PaneButtons
        style={{
          marginTop: 12,
          flexDirection: modalButtonMode ? 'row-reverse' : 'row',
        }}
      >
        <Button type="primary" disabled={!canSave} onClick={handleSave}>
          Save
        </Button>
        {onCancel && <Button onClick={handleCancel}>Cancel</Button>}
      </PaneButtons>
    </Column>
  );
};

export const PaneButtons = styled(Row)`
  gap: 6px;

  .ant-btn {
    width: 120px;
  }
`;

const SelectReviewProductName: React.FC<{
  disabled?: boolean;
  value?: ProductName;
  onChange?: (value: ProductName) => void;
}> = ({ disabled, value, onChange }) => {
  const items = Object.keys(ProductName).map((key) => ({
    value: ProductName[key],
    label: productDetails(ProductName[key]).titleCase,
  }));

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={items}
    />
  );
};

const SelectReviewShareMode: React.FC<{
  disabled?: boolean;
  value?: ShareMode;
  onChange?: (value: ShareMode) => void;
}> = ({ disabled, value, onChange }) => {
  const items = [
    {
      value: ShareMode.SHARED,
      label: (
        <Typography.Text
          onClick={() => {
            onChange?.(ShareMode.SHARED);
          }}
        >
          Shared with the participant at the end of the cycle
        </Typography.Text>
      ),
    },
    {
      value: ShareMode.NOT_SHARED,
      label: (
        <Typography.Text
          onClick={() => {
            onChange?.(ShareMode.NOT_SHARED);
          }}
        >
          Only shared with the participant&apos;s manager
        </Typography.Text>
      ),
    },
  ];

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={items}
    />
  );
};

const SelectMax: React.FC<{
  disabled?: boolean;
  value?: number;
  onChange?: (value: number) => void;
}> = ({ disabled, value, onChange }) => {
  const items = [
    {
      value: 0,
      label: 'Unlimited',
    },
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 6,
      label: 6,
    },
  ];

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={onChange}
      options={items}
    />
  );
};

const getDefaultConfig = (
  enableNewCycleScheduling: boolean,
): Omit<IReviewCycleConfig, 'name'> => {
  return {
    peerReviewCycleEnabled: false,
    peerSelectionStartDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 7), 'yyyy-MM-dd')
      : undefined,
    peerSelectionEndDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 8), 'yyyy-MM-dd')
      : undefined,
    peerApprovalStartDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 7), 'yyyy-MM-dd')
      : undefined,
    peerApprovalEndDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 9), 'yyyy-MM-dd')
      : undefined,
    peerFeedbackStartDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 10), 'yyyy-MM-dd')
      : undefined,
    peerFeedbackEndDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 12), 'yyyy-MM-dd')
      : undefined,
    peerReviewShareMode: ShareMode.SHARED,
    peerReviewMaxPeersPerParticipant: 0,
    peerReviewMaxRequestsPerPeer: 0,
    upwardReviewCycleEnabled: false,
    upwardFeedbackStartDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 10), 'yyyy-MM-dd')
      : undefined,
    upwardFeedbackEndDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 12), 'yyyy-MM-dd')
      : undefined,
    selfReflectionCycleEnabled: false,
    selfReviewStartDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 7), 'yyyy-MM-dd')
      : undefined,
    selfReviewEndDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 9), 'yyyy-MM-dd')
      : undefined,
    selfReflectionProductName: ProductName.REVIEW,
    managerReviewProductName: ProductName.REVIEW,
    managerReflectionCycleEnabled: false,
    managerReviewStartDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 13), 'yyyy-MM-dd')
      : undefined,
    managerReviewEndDate: !enableNewCycleScheduling
      ? format(addDays(new Date(), 17), 'yyyy-MM-dd')
      : undefined,
  };
};
